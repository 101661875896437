@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  /* Untuk browser berbasis WebKit (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* opsional, buat scrollbar tak terlihat */
  }

  /* Untuk Firefox */
  * {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  /* Untuk Internet Explorer 10+ */
  *::-ms-scrollbar {
    display: none;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    background-color: #0a192f;
  }

  /* Intro start */
  #text {
    opacity: 0;
    animation: fadeIn 1s ease-in forwards;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  #line {
    animation: loading 2s forwards cubic-bezier(0, 0, 0, 0);
  }

  @keyframes loading {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
  /* Intro end */

  /* Header Start */
  #header {
    transition: top 0.3s;
  }

  #header span {
    font-size: 12px;
  }

  #header-list a:hover {
    color: #64ffda;
  }

  #dropdown a:hover {
    color: #64ffda;
    text-shadow: 1px 1px 15px #64ffda;
  }
  /* Header End */

  /* Training Start*/

  /* Training End*/

  /* Project Start */
  #projectMain:hover #judul {
    -webkit-text-fill-color: #64ffda;
  }

  #link a:hover {
    filter: invert(79%) sepia(16%) saturate(1157%) hue-rotate(111deg)
      brightness(250%) contrast(103%);
  }
  /* Project End */

  /* Footer Start */
  #footer a:hover {
    -webkit-text-fill-color: #64ffda;
  }
  /* Footer End */

  /* SocialMedia Start */
  #gmail {
    writing-mode: vertical-rl;
  }

  #gmail:hover {
    -webkit-text-fill-color: #64ffda;
  }

  #github:hover {
    filter: invert(79%) sepia(16%) saturate(1157%) hue-rotate(111deg)
      brightness(250%) contrast(103%);
  }

  #instagram:hover {
    filter: invert(79%) sepia(16%) saturate(1157%) hue-rotate(111deg)
      brightness(250%) contrast(103%);
  }

  #linkedin:hover {
    filter: invert(79%) sepia(16%) saturate(1157%) hue-rotate(111deg)
      brightness(250%) contrast(103%);
  }
  /* Social Media End */
}
